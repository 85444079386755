import React, { useState } from "react";

import { withAuth0, Auth0ContextInterface } from "@auth0/auth0-react";
import {Button, useToast} from "@chakra-ui/react"

import { createCheckoutSession } from "../../api";

interface PurchaseButtonProps {
  auth0: Auth0ContextInterface,
  stripePriceId: string,
  isSubscription: boolean,
};

const PurchaseButton = ({auth0, stripePriceId, isSubscription}: PurchaseButtonProps) => {
  const [state, setState] = useState({
    loading: false,
  });

  const toast = useToast()
  const failureToast = () => toast({
    title: `Failed to create a checkout session.`,
    description: "Reach out to hey@zopeful.com for help.",
    status: "error",
    duration: 10000,
    isClosable: true,
  })

  const createCheckoutSessionForPurchase = async () => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true,
      }));

      const token = await auth0.getAccessTokenSilently();

      const response = await createCheckoutSession(
        token,
        stripePriceId,
        isSubscription ? "subscription" : "payment",
        `${window.location.origin}${window.location.pathname}?purchase-success=true`,
        `${window.location.origin}${window.location.pathname}`,
      );

      const responseData = await response.json();

      if (!response.ok) {
        failureToast()
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      } else {
        window.location.href = responseData["url"];
      }

    } catch (error) {
      failureToast()
      setState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  }

  return (
    <Button
      onClick={createCheckoutSessionForPurchase}
      variant="solid"
      colorScheme="green"
      mb="2rem"
      isDisabled={state.loading}
    >
      {isSubscription ? "Subscribe" : "Buy now"}
    </Button>
  )
};

export default withAuth0(PurchaseButton);
