import React, { useEffect, useState } from "react";
import { Box, Heading, Flex, Link } from "@chakra-ui/react"

import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";

import {getInvoices, getInvoiceContributions, getSubscriptions} from "../../api";
import EmptyStateBlurb from "../../components/EmptyStateBlurb";
import LoadingSpinner from "../../components/LoadingSpinner";
import SortableTable, {Column} from "../../components/tables/SortableTable";
import {
  Invoice,
  InvoiceContribution,
  PortfolioPartner,
  SolutionTypeAggregation,
  Subscription,
} from '../../types'
import { getSolutionTypeAggregations } from '../../utils/utils'
import BasePage from "../BasePage"
import DashboardContributionCardSection from './DashboardContributionCardSection'
import DashboardBlurbSection from './DashboardBlurbSection'
import DashboardSolutionTypes from './DashboardSolutionTypes'
import DashboardContributionChart from './DashboardContributionsChart'

type DashboardState = {
  invoiceContributionData: {
    data: InvoiceContribution[],
    solutionTypeAggegations: SolutionTypeAggregation[],
    isLoading: boolean
  };
  invoiceData: {
    data: Invoice[],
    isLoading: boolean
  }
  portfolioPartnersData: {
    data: Invoice[],
    isLoading: boolean
  }
  subscriptionData: {
    data: Subscription[],
    isLoading: boolean,
  }
}
interface DashboardProps {
  auth0: Auth0ContextInterface;
};
const partnerTableColumns: Column[] = [
  {header: "Partner", accessor: "partner"},
  {header: "Solution Type", accessor: "solutionType"},
  {header: "Company Stage", accessor: "companyStage"},
  {header: "Country", accessor: "country"},
];

function Dashboard({ auth0 }: DashboardProps) {
  const [state, setState] = useState<DashboardState>({
    invoiceContributionData: {
      data: [],
      solutionTypeAggegations: [],
      isLoading: true,
    },
    invoiceData: {
      data: [],
      isLoading: true,
    },
    portfolioPartnersData: {
      data: [],
      isLoading: true,
    },
    subscriptionData: {
      data: [],
      isLoading: true,
    }
  });

  useEffect(() => {
    const preprocessPartnersInPortfolio = (partner: PortfolioPartner) => {
      return {
        partner: (
          <Link color="teal.500" href={partner.partnerInformationLink} isExternal>
            {partner.partner}
          </Link>
        ),
        solutionType: partner.solutionType,
        companyStage: partner.companyStage,
        country: partner.country,
      };
    };

    setState(prevState => ({
      ...prevState,
      portfolioPartnersData: {...prevState.portfolioPartnersData, isLoading: true}
    }));
    fetch('/dashboardStubData.json')
      .then(response => response.json())
      .then(jsonData => setState(prevState => ({
          ...prevState,
          portfolioPartnersData: {
            data: jsonData.partnersInPortfolio.map(preprocessPartnersInPortfolio),
            isLoading: false,
          }
        }
      )))
      .catch(error => {
        console.error(error)
        setState(prevState => ({
          ...prevState,
          portfolioPartnersData: {...prevState.portfolioPartnersData, isLoading: false}
        }));
      });
  }, [setState]);

  useEffect(() => {
    const fetchData = async () => {
      setState(prevState => ({
        ...prevState,
        invoiceContributionData: {...prevState.invoiceContributionData, isLoading: true}
      }));
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getInvoiceContributions(token);

        const data = await response.json();
        const solutionTypeAggregation: SolutionTypeAggregation[] = getSolutionTypeAggregations(data)

        if (response.ok) {
          setState(prevState => ({
            ...prevState,
            invoiceContributionData: {
              data: data,
              solutionTypeAggegations: solutionTypeAggregation,
              isLoading: false
            }
          }));
        }
      } catch (error) {
        console.error(error)
        setState(prevState => ({
          ...prevState,
          invoiceContributionData: {...prevState.invoiceContributionData, isLoading: false}
        }));
      }
    }

    fetchData();

  }, [auth0, setState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getInvoices(token);

        const data = await response.json();

        if (response.ok) {
          setState(prevState => ({...prevState, invoiceData: {data: data, isLoading: false}}));
        }
       } catch (error) {
        console.error(error)
        setState(prevState => ({
          ...prevState,
          invoiceData: {...prevState.invoiceData, isLoading: false}
        }));
      }
    }

    fetchData();

  }, [auth0, setState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getSubscriptions(token);

        const data = await response.json();

        if (response.ok) {
          setState(prevState => ({...prevState, subscriptionData: {data: data, isLoading: false}}));
        }
      } catch (error) {
        console.error(error)
        setState(prevState => ({...prevState, subscriptionData: {...prevState.subscriptionData, isLoading: false}}));
      }
    }

    fetchData();

  }, [auth0, setState]);

  const renderDashboardContributionChart = () => {
    if (state.invoiceData.isLoading) {
      return (<LoadingSpinner />)
    }
    return (
      state.invoiceData.data.length > 0 &&
      <DashboardContributionChart data={state.invoiceData.data}/>
    )
  }
  const renderSolutionTypeTable = () => {
    if (state.invoiceContributionData.isLoading) {
      return (<LoadingSpinner />)
    }
    return (
      state.invoiceContributionData.solutionTypeAggegations.length > 0 &&
      <DashboardSolutionTypes
        data={state.invoiceContributionData.solutionTypeAggegations}
      />
    )
  }
  const renderPortfolioPartnersTable = () => {
    if (state.portfolioPartnersData.isLoading) {
      return (<LoadingSpinner />)
    }
    return (
      state.portfolioPartnersData.data &&
      <SortableTable
        data={state.portfolioPartnersData.data}
        columns={partnerTableColumns}
      />
    )
  }

  const renderEmptyState = () => {
    return (
      <Box>
        <Box m={4} bg="white" >
          <EmptyStateBlurb
            heading="Make your first contribution to carbon removal!"
            message="Join with a monthly subscription or a one-time purchase. Every tonne matters."
            link={{to: "/purchase", text: "Start your portfolio today"}}
          />
        </Box>
        <Box p={4} m={4} bg="white" >
          <Heading size='md'as='h2'>Suppliers in Portfolio-1</Heading>
          {renderPortfolioPartnersTable()}
        </Box>
      </Box>
    )
  };

  const renderData = () => {
    return (
      <Box display="flex" flexDirection="column" h="100vh" p={4} boxSizing="border-box" mb="4rem">
        <Flex flex="1" mb={4}>
          <Box w="50%" mr={2} borderRadius="md">
            <DashboardBlurbSection />
          </Box>
          <Box w="50%" pb={4} pt={4} pr={2} pl={2} bg="white" ml={2} borderRadius="md">
            <Heading size='md'as='h2' pl={2}>Your contributions over time</Heading>
            {renderDashboardContributionChart()}
          </Box>
        </Flex>
        <Flex direction="row" justify="space-between" mb={4}>
          {
            (!state.invoiceData.isLoading && !state.subscriptionData.isLoading) &&
            <DashboardContributionCardSection
              invoices={state.invoiceData.data}
              subscriptions={state.subscriptionData.data}
            />
          }
        </Flex>

        <Box display="flex" flex="3">
          <Box w="40%" p={4} bg="white" mr={2} borderRadius="md">
            <Heading size='md'as='h2'>Portfolio by solution type</Heading>
            {renderSolutionTypeTable()}
          </Box>
          <Box w="60%" ml={2} borderRadius="md">
            <Box p={4} bg="white" >
              <Heading size='md'as='h2'>Suppliers in Portfolio-1</Heading>
              {renderPortfolioPartnersTable()}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return BasePage(
    <>
      <Heading as="h2" size="lg">
        Dashboard
      </Heading>
      {
        !state.invoiceContributionData.isLoading && state.invoiceContributionData.data.length === 0
        ? renderEmptyState()
        : renderData()
      }
    </>
  );
}

export default withAuth0(Dashboard);
