import React from "react";
import {
  Card, Box,Tab, Tabs, TabList, TabPanel, TabPanels, Stat,
  StatGroup, StatLabel, StatNumber, StatHelpText, StatArrow
} from "@chakra-ui/react"

export type CardTab = {
  listTitle: string;
  statNumber: string;
  isIncrease: boolean;
  changeAmountString?: string;
};

export interface CardData {
  label: string;
  tabs: CardTab[];
};

const DashboardContributionCard = ({ label, tabs }: CardData) => {
  return (
    <Card m={2} pt={3} pl={3} pr={2} display="flex" flexDirection="column">
      <Box flex="1">
        <StatGroup>
          <Stat>
            <StatLabel fontSize="sm">{label}</StatLabel>
            <Tabs size='sm' variant='soft-rounded' colorScheme='green'>
              <TabList>
                {tabs.map((tab:CardTab) => (<Tab>{tab.listTitle}</Tab>))}
              </TabList>
              <TabPanels>
                {tabs.map((tab:CardTab) => (
                  <TabPanel>
                    <StatNumber fontSize="lg">{tab.statNumber}</StatNumber>
                    {
                      tab.changeAmountString &&
                      <StatHelpText fontSize="xs">
                        <StatArrow type={tab.isIncrease ? 'increase' : 'decrease'} />
                        {tab.changeAmountString}
                      </StatHelpText>
                    }
                  </TabPanel>
                )) }
              </TabPanels>
            </Tabs>
          </Stat>
        </StatGroup>
      </Box>
    </Card>
  );
}

export default DashboardContributionCard;
