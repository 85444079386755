import React from "react";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react"

import { CurrencyType } from '../../types';
import CurrencyNumber from '../../utils/CurrencyNumber';
import PurchaseButton from './PurchaseButton';

interface OneTimePurchaseCardProps {
  stripePriceId: string,
  kgCarbonRemoval: number,
  description: string,
  currencyAmount: number,
  currencyType: CurrencyType,
};

const OneTimePurchaseCard = (
  {
    stripePriceId,
    kgCarbonRemoval,
    description,
    currencyAmount,
    currencyType,
  }: OneTimePurchaseCardProps) => {

  const carbonRemovalString = kgCarbonRemoval >= 1000 ? `${kgCarbonRemoval/1000} tonnes` : `${kgCarbonRemoval}kg`;
  const currencyNumber = new CurrencyNumber(currencyAmount, currencyType);
  const priceString = `For ${currencyNumber.toString()}`;

  return (
    <Card maxW="xs" align="center">
      <CardHeader>
        <Stack mt="6" spacing="3" textAlign="center">
          <Box textAlign="center">
            <Heading size="sm" pb="0.8rem">REMOVE CO<sub>2</sub></Heading>
            <Heading size="lg" pb="0.8rem" color="green">{carbonRemovalString}</Heading>
            <Heading size="sm" >{priceString}</Heading>
          </Box>
        </Stack>
      </CardHeader>
      <Box w="80%">
        <Divider size="sm"/>
      </Box>
      <CardBody textAlign="center">
        <PurchaseButton stripePriceId={stripePriceId} isSubscription={false} />
        <Box>
          {description.split("\n").map(text => <Text key={text} pb="8px">{text}</Text>)}
        </Box>
      </CardBody>
    </Card>
  )
};


export default OneTimePurchaseCard;
