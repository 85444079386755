import React, { useState } from "react";

import { withAuth0, Auth0ContextInterface } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
} from "@chakra-ui/react"

import { createSessionToUpdateSubscription, createSessionToCancelSubscription } from "../../api";

interface SubscriptionTableRowPopoverProps {
  auth0: Auth0ContextInterface
  stripeSubscriptionId: string
  stripeCustomerId: string
  cancelAtPeriodEnd: boolean
};

enum UpdateType {
  Update = "Update",
  Cancel = "Cancel",
}

const SubscriptionTableRowPopover = ({
  auth0,
  stripeSubscriptionId,
  stripeCustomerId,
  cancelAtPeriodEnd,
}: SubscriptionTableRowPopoverProps) => {

  const [state, setState] = useState({
    updatingDisabled: false,
  });

  const toast = useToast()
  const failureToast = (updateType: UpdateType) => toast({
    title: `Failed to ${updateType === UpdateType.Update ? "update" : "cancel"} your subscription.`,
    description: "Reach out to hey@zopeful.com for help.",
    status: "error",
    duration: 10000,
    isClosable: true,
  })

  const updateSubscription = async (updateType: UpdateType) => {
    try {
      setState(prevState => ({
        ...prevState,
        updatingDisabled: true,
      }));

      const token = await auth0.getAccessTokenSilently();

      let response;
      if (updateType === UpdateType.Update) {
        response = await createSessionToUpdateSubscription(
          token,
          stripeSubscriptionId,
          stripeCustomerId,
          `${window.location.origin}${window.location.pathname}`
        );
      } else {
        response = await createSessionToCancelSubscription(
          token,
          stripeSubscriptionId,
          stripeCustomerId,
          `${window.location.origin}${window.location.pathname}`
        );
      }

      const responseData = await response.json();

      if (!response.ok) {
        failureToast(updateType)
        setState(prevState => ({
          ...prevState,
          updatingDisabled: false,
        }));
      } else {
        window.location.href = responseData["url"];
      }

    } catch (error) {
      failureToast(updateType)
      setState(prevState => ({
        ...prevState,
        updatingDisabled: false,
      }));
    }
  }

  // TODO: update to be able to undo subscription cancel.
  return (
    <Popover>
      <PopoverTrigger>
        <Button>...</Button>
      </PopoverTrigger>
      <PopoverContent w="16rem" h="100%" mr="2rem">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody w="100%">
          <Box pb="12px" pt="25px">
            <Button
              w="100%"
              isDisabled={state.updatingDisabled}
              onClick={() => updateSubscription(UpdateType.Update)}
            >
              Manage Subscription
            </Button>
          </Box>
          <Box pb="12px">
            <Button
              w="100%"
              isDisabled={state.updatingDisabled || cancelAtPeriodEnd}
              onClick={() => updateSubscription(UpdateType.Cancel)}
            >
              Cancel Subscription
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
};


export default withAuth0(SubscriptionTableRowPopover);
