import React from "react";

import {SimpleGrid} from "@chakra-ui/react"

import {PaymentLink} from "../../types";
import OneTimePurchaseCard from './OneTimePurchaseCard';
import SubscriptionCard from './SubscriptionCard';

interface PurchaseCardsSectionProps {
  oneTime: boolean,
  paymentLinks: PaymentLink[],
}

const PurchaseCardsSection = ({oneTime, paymentLinks}: PurchaseCardsSectionProps) => {
  return (
    <SimpleGrid minChildWidth="350px" spacingX="10px" spacingY="40px">
      {
        oneTime
        ? paymentLinks.map((link) => {
          return <OneTimePurchaseCard
            key={link.stripePriceId}
            stripePriceId={link.stripePriceId}
            kgCarbonRemoval={link.kgCarbonRemoval}
            description={link.description}
            currencyType={link.currencyType}
            currencyAmount={link.currencyAmount / 100}
          />
        })
        : paymentLinks.map((link) => {
          return <SubscriptionCard
            key={link.stripePriceId}
            stripePriceId={link.stripePriceId}
            kgCarbonRemoval={link.kgCarbonRemoval}
            description={link.description}
            currencyType={link.currencyType}
            currencyAmount={link.currencyAmount / 100}
          />
        })
      }
    </SimpleGrid>
  )
}

export default PurchaseCardsSection;
