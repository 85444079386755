import { Routes, Route } from 'react-router-dom';
import AuthRequiredRoute from './utils/AuthRequiredRoute';

import { useAuth0 } from "@auth0/auth0-react";

import ContributionsPage from './pages/contributions/ContributionsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import PurchasePage from './pages/purchase/PurchasePage';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <>
      <Routes>
         <Route
           path="/dashboard"
           element={<AuthRequiredRoute component={DashboardPage} />}
         />
         <Route
           path="/contributions"
           element={<AuthRequiredRoute component={ContributionsPage} />}
         />
         <Route
           path="/purchase"
           element={<AuthRequiredRoute component={PurchasePage} />}
         />
         <Route
           path="/"
           element={<AuthRequiredRoute component={DashboardPage} />}
         />
       </Routes>
    </>
  );
};

export default App;
