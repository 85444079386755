import React, { ReactElement } from 'react';
import { Grid, GridItem } from '@chakra-ui/react'

import Navbar from '../components/nav/Navbar';

const BasePage = (content: ReactElement) => {
  return (
    <>
      <Grid
        minH='100vh'
        templateColumns='1fr 5fr'
        overflowX="hidden"
      >
        <GridItem pt='3vh' pl='2vw' pr='2vw' pb='2vh' colSpan={1} bg='#ffffff'>
          <Navbar />
        </GridItem>

        <GridItem
          pt="3vh"
          pb="3vh"
          pl="2vw"
          pr="2vw"
          colSpan={1}
          width="100%"
          bg="#F8F9FA"
          overflowY="auto"
        >
          {content}
        </GridItem>
      </Grid>
    </>
  );
};

export default BasePage;
