import React from "react";
import { Box, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";



const DashboardBlurbSection = () => {
  return (
    <Box
      flexDirection="column"
      w="100%"
      h="100%"
      display='flex'
      alignItems='center'
      justifyContent='center'
      textAlign="left"
      color='white'
      textShadow='0 0 20px black'
      background="url('/hopeful-future.png') center/cover no-repeat"
    >
      <Box
        w="100%"
        h="100%"
        brightness="10%"
        filter="auto"
        bgGradient='linear(to-b, rgba(52,148,97,0.5), rgba(255,255,255,0.01))'
        // Another alternative that looks nice.
        // bgGradient='linear(to-b, rgba(20,100,53,0.5), rgba(255,255,255,0.01))'
      >
        <Box p={4}>
          <Text fontSize="xl" mb={1} fontWeight="700" brightness="100%">
            Thank you!
          </Text>
          <Text fontSize="lg" mb={1} fontWeight="500">
            Great to have you with us. Welcome. Your Zopeful contributions will help remove
            carbon from the atmosphere and safely store it. It's locked away where it can't
            contribute to climate change.
          </Text>
          <Text fontSize="lg" fontWeight="500">
            Keep going!
          </Text>
        </Box>
      </Box>
      <Button m={4} as={Link} to="/contributions" colorScheme="green" alignSelf="flex-end">
        Manage your contributions
      </Button>
    </Box>
  );
};

export default DashboardBlurbSection;
