// Auth0 data
export const apiOrigin = process.env.REACT_APP_BACKEND_API
  ? process.env.REACT_APP_BACKEND_API
  : "http://localhost:5000";
export const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

// Endpoints
export const invoicesEndpoint = `${apiOrigin}/invoices/`;
export const invoiceContributionsEndpoint = `${apiOrigin}/invoice-contributions/`;
export const stripeEndpoint = `${apiOrigin}/stripe/`;
export const subscriptionsEndpoint = `${apiOrigin}/subscriptions/`;
export const usersEndpoint = `${apiOrigin}/users/`;

// API Functions
export async function callApi(accessToken: string, endpoint: string, method: string, data?: any) {
  const body = data ? JSON.stringify(data) : null;

  return await fetch(endpoint, {
    method,
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: body,
  });
}

export async function getUser(accessToken: string) {
  return callApi(accessToken, `${usersEndpoint}me/`, "GET")
};

export async function getInvoices(accessToken: string) {
  return callApi(accessToken, `${invoicesEndpoint}me/`, "GET")
};

export async function getInvoiceContributions(accessToken: string) {
  return callApi(accessToken, `${invoiceContributionsEndpoint}me/`, "GET")
};

export async function getSubscriptions(accessToken: string) {
  return callApi(accessToken, `${subscriptionsEndpoint}me/`, "GET")
};

export async function createCheckoutSession(
  accessToken: string,
  stripePriceId: string,
  mode: string,
  successUrl: string,
  cancelUrl: string,
) {
  const payload: Record<string, any> = {
    stripePriceId: stripePriceId,
    mode: mode,
    successUrl: successUrl,
    cancelUrl: cancelUrl,
  };
  return callApi(
    accessToken,
    `${stripeEndpoint}checkout-session/`,
    "POST",
    payload,
  )
}

export async function createSessionToUpdateSubscription(
  accessToken: string,
  stripeSubscriptionId: string,
  stripeCustomerId: string,
  returnUrl: string,
) {
  return callApi(
    accessToken,
    `${subscriptionsEndpoint}stripe/${stripeSubscriptionId}`,
    "PUT",
    {"stripeCustomerId": stripeCustomerId, "returnUrl": returnUrl}
  )
};

export async function createSessionToCancelSubscription(
  accessToken: string,
  stripeSubscriptionId: string,
  stripeCustomerId: string,
  returnUrl: string,
) {
  return callApi(
    accessToken,
    `${subscriptionsEndpoint}stripe/${stripeSubscriptionId}`,
    "DELETE",
    {"stripeCustomerId": stripeCustomerId, "returnUrl": returnUrl}
  )
};
