import React from "react";

import { Button } from '@chakra-ui/react'
import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";


interface LoginButtonProps {
  auth0: Auth0ContextInterface;
};

const LoginButton = ({auth0}:LoginButtonProps) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = auth0;

  return (
    !isAuthenticated &&
    !isLoading &&
    <Button
      colorScheme="green"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </Button>
  );
};

export default withAuth0(LoginButton);
