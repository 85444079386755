import React from 'react'
import { Circle, HStack, Flex, Box, Link, Table, Tag, Text, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { VictoryPie, VictoryLabel } from 'victory';

import {SolutionTypeConfig, SolutionTypeAggregation} from '../../types'

type DashboardSolutionTypesProps = {
  data: SolutionTypeAggregation[];
}

const DashboardSolutionTypes: React.FC<DashboardSolutionTypesProps> = ({ data }) => {
  return (
    <Box>
      <Flex justifyContent='center' h="200px" mt='4' w="100%" alignItems='center'>
        <Box w="250px">
          <svg viewBox="0 0 300 300">
            <VictoryPie
              standalone={false}
              innerRadius={100}
              radius={120}
              data={data.map(d => ({
                x: d.type,
                y: d.totalVolume,
                color: SolutionTypeConfig[d.type].color
              }))}
              style={{
                data: {
                  fill: ({ datum }) => datum.color,
                },
                labels: {
                  display: 'none',
                },
              }}
              origin={{ x: 150, y: 150 }}
            />
            <circle cx="150" cy="150" r="100" fill="white" stroke="#E3EBDC" strokeWidth="10"/>
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 50 }}
              x={150} y={130}
              text="55%"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 16 }}
              x={150} y={160}
              text="permanence over 500 years"
            />
          </svg>
        </Box>

      </Flex>
      <Box mt='8'>
        <Table size='sm' variant='simple'>
          <Thead>
            <Tr>
              <Th>Solution Type</Th>
              <Th isNumeric>Volume (kg)</Th>
              <Th isNumeric>Total (%)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => {
              const config = SolutionTypeConfig[item.type]
              const color = config.color
              const name = config.name
              return (
              <Tr key={item.type}>
                <Td>
                  <HStack spacing="2">
                    <Circle size="8px" bg={color} />
                    <Text>
                      <Link href={config.solutionTypeInformationLink} color="teal.500" isExternal>
                        {name}
                      </Link>
                    </Text>
                  </HStack>
                </Td>
                <Td>{item.totalVolume.toLocaleString()}</Td>
                <Td>
                  <Tag size="sm" borderRadius="full" bg={color} color="black">
                    {item.percentage}%
                  </Tag>
                </Td>
              </Tr>
            )
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}

export default DashboardSolutionTypes;
