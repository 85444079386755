import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import {Heading, Link, Tab, Tabs, TabList, TabPanel, TabPanels, Text } from "@chakra-ui/react"
import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";

import LoadingSpinner from "../../components/LoadingSpinner";
import {PaymentLink} from "../../types";
import BasePage from "../BasePage"
import PurchaseCardsSection from './PurchaseCardsSection';
import PurchaseSuccessModal from './PurchaseSuccessModal';


interface PurchasePageProps {
  auth0: Auth0ContextInterface;
};
interface PurchasePageState {
  loading: boolean;
  oneTimePaymentLinks: PaymentLink[];
  subscriptionPaymentLinks: PaymentLink[];
  error: string | null;
};

const PurchasePage = ({auth0}:PurchasePageProps) => {
  const [state, setState] = useState<PurchasePageState>({
    loading: true,
    oneTimePaymentLinks: [],
    subscriptionPaymentLinks: [],
    error: null,
  });

  
  // Control the modal using the URL query parameter `purchase-success`
  // Note: This is slightly awkward, but I'm not sure what a better solution is.
  // Maybe the issue is that I'm using a modal in a way it wasn't intended. 
  // I think it's fine for now, but it's something we may want to improve in the future.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const purchaseSuccess = queryParams.get('purchase-success');

  const navigate = useNavigate(); 
  const handleClose = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('purchase-success');
    navigate(`${window.location.pathname}?${queryParams.toString()}`); 
  };


  useEffect(() => {
    fetch(`/${process.env.REACT_APP_PURCHASE_PAGE_DATA_FILE}`)
      .then(response => response.json())
      .then(data => {
        setState(prevState => ({
          ...prevState,
          loading: false,
          subscriptionPaymentLinks: data["subscriptionPaymentLinks"],
          oneTimePaymentLinks: data["oneTimePaymentLinks"],
        }));
        return data;
      })
      .catch(error => {
        setState(prevState => ({
          ...prevState,
          loading: false,
        }));
        console.error(error);
      });
  }, [setState]);


  return BasePage(
    <>
      <Heading as="h2" size="lg">
        Purchase CDR
      </Heading>
      <PurchaseSuccessModal isOpen={purchaseSuccess === "true"} onClose={handleClose} />
      <Text pt="1vh" pb="2rem" fontSize="md" maxW="45rem">
        Make a carbon dioxide removal (CDR) purchase or a monthly subscription. For custom bundles in kgs
        or tonnes or to buy for a company or employee carbon removal program, contact us
        <Link href="https://zopeful.com/contact-us" isExternal color='teal.500'> here.</Link>
      </Text>
      <Text pt="1vh" pb="2rem" fontSize="md" maxW="45rem">
        Prices are in US Dollars ($). If your local currency is Euros, British Pounds, Canadian or
        Australian Dollars, it'll be converted at checkout. Payments are secure and fully encrypted.
      </Text>
      <Tabs size='md' variant='enclosed'>
        <TabList>
          <Tab>Monthly Subscriptions</Tab>
          <Tab>One-time Purchases</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {
              state.loading
              ? <LoadingSpinner />
              : <PurchaseCardsSection oneTime={false} paymentLinks={state.subscriptionPaymentLinks}/>
            }
          </TabPanel>
          <TabPanel>
            {
              state.loading
              ? <LoadingSpinner />
              : <PurchaseCardsSection oneTime={true} paymentLinks={state.oneTimePaymentLinks}/>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default withAuth0(PurchasePage);
