import React, { useState, useEffect } from "react";

import {Box, Heading} from "@chakra-ui/react"
import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";

import {getInvoices} from "../../api";
import EmptyStateBlurb from "../../components/EmptyStateBlurb";
import LoadingSpinner from "../../components/LoadingSpinner";
import SortableTable, {Column} from "../../components/tables/SortableTable";
import {Invoice} from "../../types";
import CurrencyNumber from '../../utils/CurrencyNumber';

interface ContributionsTableProps {
  auth0: Auth0ContextInterface;
};
interface ContributionsTableState {
  loading: boolean;
  contributions: Invoice[];
  error: string | null;
};

const columns: Column[] = [
  {header: "Purchase Name", accessor: "purchaseName"},
  {header: "Carbon Removal (KG)", accessor: "kgCarbonRemoval"},
  {header: "Purchase Date", accessor: "purchaseDate"},
  {header: "Total Amount", accessor: "currencyAmount"},
];

const ContributionsTable = ({auth0}:ContributionsTableProps) => {
  const [state, setState] = useState<ContributionsTableState>({
    loading: true,
    contributions: [],
    error: null,
  });

  useEffect(() => {
    const getContributionRowDataFromContribution = (contribution: Invoice) => {
      return {
        purchaseName: contribution.portfolio.name,
        kgCarbonRemoval: contribution.kgCarbonRemoval,
        purchaseDate: new Date(contribution.purchaseDate),
        currencyAmount: new CurrencyNumber(contribution.currencyAmount, contribution.currencyType, true),
      };
    };

    const fetchData = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();

        const response = await getInvoices(token);

        const responseData = await response.json();

        if (!response.ok) {
          setState(prevState => ({
            ...prevState,
            loading: false,
            contributions: [],
            error: responseData.error,
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            loading: false,
            contributions: responseData.map(getContributionRowDataFromContribution),
          }));
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          loading: false,
          contributions: [],
          error: error.error,
        }));
      }
    }

    fetchData();

  }, [auth0, setState]);

  const renderTable = () => {
    return (
      <>
      {
        state.contributions.length !== 0
        ? <SortableTable
            data={state.contributions}
            columns={columns}
            startingSortConfig={{key: "purchaseDate", direction: "descending"}}
          />
        : <EmptyStateBlurb
          heading="Make your first contribution to carbon removal!"
          message="Join with a monthly subscription or a one-time purchase. Every tonne matters."
          link={{to: "/purchase", text: "Start your portfolio today"}}
        />
      }
      </>
    )
  }

  return (
    <Box width="100%" >
      <Heading as="h2" pb="2vh" size="md">
        Carbon removal purchases
      </Heading>
      {state.loading ? <LoadingSpinner /> : renderTable()}
    </Box>
  );
};


export default withAuth0(ContributionsTable);
