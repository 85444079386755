import React from "react";


import { CurrencyType, Invoice, Subscription, SubscriptionStatus} from '../../types'
import DashboardContributionCard, { CardData } from './DashboardContributionCard'
import CurrencyNumber from '../../utils/CurrencyNumber';

interface DashboardContributionCardSectionProps {
  invoices: Invoice[];
  subscriptions: Subscription[];
  numberMonthsToProject?: number[];
};
const DashboardContributionCardSection = ({
  invoices,
  subscriptions,
  numberMonthsToProject=[3, 6, 12],
}: DashboardContributionCardSectionProps) => {

  const getContributionsToDateKgCarbonRemoval = (data: Invoice[]): CardData => {
    const currentYear = new Date().getFullYear();

    let totalKgCarbonRemoval = 0;
    let totalKgCarbonRemovalCurrentYear = 0;

    for (let i = 0; i < data.length; i++) {
      totalKgCarbonRemoval += data[i].kgCarbonRemoval;
      if (new Date(data[i].purchaseDate).getFullYear() === currentYear) {
        totalKgCarbonRemovalCurrentYear += data[i].kgCarbonRemoval;
      }
    }
    totalKgCarbonRemoval = Math.round(totalKgCarbonRemoval);
    totalKgCarbonRemovalCurrentYear = Math.round(totalKgCarbonRemovalCurrentYear);

    return {
      "label": "Contributions to date (kg)",
      "tabs": [
        {
          "listTitle": "kilos (kg)",
          "statNumber": `${totalKgCarbonRemoval.toLocaleString()} kg`,
          "isIncrease": true,
          "changeAmountString": `${totalKgCarbonRemovalCurrentYear.toLocaleString()} kg in 2024`
        },
        {
          "listTitle": "tonnes (t)",
          "statNumber": `${totalKgCarbonRemoval/1000} t`,
          "isIncrease": true,
          "changeAmountString": `${totalKgCarbonRemovalCurrentYear/1000} t in 2024`
        }
      ]
    }
  };

  const getContributionsToDateCurrencySpent = (data: Invoice[]): CardData => {
    const currentYear = new Date().getFullYear();

    const currencyData = data.reduce(
      (
        acc: Record<string, {totalCurrencySpent: number, totalCurrencySpentCurrentYear: number}>,
        item: Invoice,
      ) => {
        if (item.currencyType) {
          if (!acc[item.currencyType]) {
            acc[item.currencyType] = {totalCurrencySpent: 0, totalCurrencySpentCurrentYear: 0};
          }
          acc[item.currencyType].totalCurrencySpent += item.currencyAmount;
          if (new Date(item.purchaseDate).getFullYear() === currentYear) {
            acc[item.currencyType].totalCurrencySpentCurrentYear += item.currencyAmount;
          }
        }
        return acc;
      },
      {} as Record<string, {totalCurrencySpent: number, totalCurrencySpentCurrentYear: number}>
    );
    return {
      "label": "Contributions to date ($/€/£)",
      "tabs": Object.entries(currencyData).map(([key, value]) => {
        const totalCurrencySpent = new CurrencyNumber(
          Math.round(value.totalCurrencySpent), key as CurrencyType, true
        );
        const totalCurrencySpentCurrentYear = new CurrencyNumber(
          Math.round(value.totalCurrencySpentCurrentYear), key as CurrencyType, true
        );
        return {
          "listTitle": key,
          "statNumber": totalCurrencySpent.toString(),
          "isIncrease": totalCurrencySpentCurrentYear.currencyAmount >= 0,
          "changeAmountString": `${totalCurrencySpentCurrentYear.toString()} in 2024`
        };
      })
    };

  };


  const getProjectedKgCarbonRemoval = (data: Subscription[]): CardData => {
    const ongoingSubscriptions = data.filter((subscription) => (
      !subscription.cancelAtPeriodEnd && subscription.status === SubscriptionStatus.Active
    ));

    const carbonRemovedPerMonth = Math.round(
      ongoingSubscriptions.reduce((acc: number, subscription: Subscription) => {
        return acc + subscription.kgCarbonRemoval;
      }, 0)
    );

    return {
      "label": "Projected Contributions (kg)",
      "tabs": numberMonthsToProject.map((months) => (
        {
          "listTitle": `${months} mths`,
          "statNumber": `${(months*carbonRemovedPerMonth).toLocaleString()} kg`,
          "isIncrease": true,
        }
      ))
    }
  };

    const getProjectedSpent = (data: Subscription[]): CardData => {
      const ongoingSubscriptions = data.filter((subscription) => (
        !subscription.cancelAtPeriodEnd && subscription.status === SubscriptionStatus.Active
      ));

      const currencyAmountPerMonth = Math.round(
        ongoingSubscriptions.reduce((acc: number, subscription: Subscription) => {
          return acc + subscription.currencyAmount;
        }, 0)
      );

      const currencyType = ongoingSubscriptions.length > 0 ? ongoingSubscriptions[0].currencyType : CurrencyType.USD;

      return {
        "label": "Projected Contributions ($/€/£)",
        "tabs": numberMonthsToProject.map((months) => (
          {
            "listTitle": `${months} mths`,
            "statNumber": new CurrencyNumber(months*currencyAmountPerMonth, currencyType, true).toString(),
            "isIncrease": true,
          }
        ))
      }
    };

  const tileData: CardData[] = [
    getContributionsToDateKgCarbonRemoval(invoices),
    getContributionsToDateCurrencySpent(invoices),
    getProjectedKgCarbonRemoval(subscriptions),
    getProjectedSpent(subscriptions),
  ];

  return (
    <>
      {
        tileData &&
        tileData.map((card, index) => (
          <DashboardContributionCard key={index} label={card.label} tabs={card.tabs} />
        ))
      }
    </>
  )
};

export default DashboardContributionCardSection;
