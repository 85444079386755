import React from "react";

import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
} from '@chakra-ui/react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


interface PurchaseSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  auth0: Auth0ContextInterface;
}

const PurchaseSuccessModal = ({isOpen, onClose, auth0}: PurchaseSuccessModalProps) => {
  const { user } = auth0;
  const { width, height } = useWindowSize()
  return (
    <>
    <Confetti width={width} height={height} numberOfPieces={isOpen ? 200 : 0}/>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay/>
        <ModalContent p={2} top="20%">
          <ModalHeader><Heading size="lg">Congrats, you're in. Great to have you with us!</Heading></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {
                  `We have sent you a confirmation of your purchase to 
                  ${user === undefined ? "your email" : user.email }.\n
                  You will also find the details in your Zopeful account.`
              }
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={onClose}>
              Return to Zopeful
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default withAuth0(PurchaseSuccessModal);
