import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Center, Image, VStack } from '@chakra-ui/react'

import {Icon, InfoOutlineIcon, StarIcon, SunIcon } from '@chakra-ui/icons'
import { FaBookReader } from "react-icons/fa";

import NavbarItem from './NavbarItem';
import LoginButton from "../buttons/LoginButton";
import LogoutButton from "../buttons/LogoutButton";

const Navbar = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Box minW="11rem">
      <Center pb="1rem" minW="11rem">
        <Image
          maxW="11rem"
          src="https://cdn.dorik.com/tmp/images/Zopeful_logo_1-(1)_w8jliv29.png"
          h="3rem"
          alt="Zopeful Climate Logo"
          />
      </Center>
      {!isAuthenticated && (
          <Box alignItems="left" minW="12rem">
            <LoginButton />
          </Box>
      )}
      {isAuthenticated && (
        <Box minW="11rem">
          <VStack m="5px" pl="1rem" position="sticky" top="0">
              {NavbarItem("/dashboard", "Dashboard", <InfoOutlineIcon mr="1rem"/>)}
              {NavbarItem("/contributions", "Contributions", <StarIcon mr="1rem"/>)}
              {NavbarItem("/purchase", "Purchase CDR", <SunIcon mr="1rem"/>)}
              {
                NavbarItem(
                  "https://zopeful.com/online-climate-change-courses",
                  "Courses",
                  <Icon as={FaBookReader} mr="1rem"/>,
                  true
                )
              }
          </VStack>
          <Center minW="12rem" bottom="5vh" position="absolute">
            <LogoutButton />
          </Center>
        </Box>

      )}
    </Box>
  );
}

export default Navbar;
